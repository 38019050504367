import React from 'react'

import howItWorks from '../../assets/images/services/graphic-services.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                Ekko
                            </span>
                            <h2>Our Approach</h2>
                            <p>Our services are founded on the industry recognized information security standards, methodologies and practices.</p>
                            <p>Implementing years of experience working with industry standard information security and risk management frameworks, we promote information security, policy, controls, risk management, and security awareness with our clients.</p>
                            <p>ekko's Agility platform is founded on proven security and compliance best practices and workflows defined by the ekko advisory and consulting services framework.</p>
                            {/* <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Data for All Your People</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>A New Breed of AI</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Analytics Business</h3>
                                    <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWorks} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs