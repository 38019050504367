import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
// import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/App/Footer';
import AboutUsContent from '../components/AboutUs/AboutUsContent';
import WhyChooseUs from '../components/AboutUs/WhyChooseUs';
//  import Testimonials from '../components/AboutUs/Testimonials'

const AboutUs = () => {
  return (
    <Layout>
      <div className='darknav'>
        <Navbar />
      </div>
      {/* <PageBanner
                pageTitle="About Us" 
            /> */}
      <AboutUsContent />
      <WhyChooseUs />
      {/* <Testimonials /> */}
      <Footer />
    </Layout>
  );
};

export default AboutUs;
