import React from 'react'
// import { Link } from 'gatsby'

// import icon4 from '../../assets/images/icons/icon4.png'
// import icon5 from '../../assets/images/icons/icon5.png'
// import icon6 from '../../assets/images/icons/icon6.png'
// import icon7 from '../../assets/images/icons/icon7.png'
 import logo from '../../assets/images/company/logo-bg.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                             <img src={logo} style={{maxWidth:'500px'}} alt="banner" /> 
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                  
                                    Company
                                </span>
                                <h2>Overview</h2>
                                <p>ekko is a leading Information Security Compliance service and ISMS Solution provider based in Palo Alto, CA.</p>
                                <p>ekko delivers information security solutions and processes, using tools that are core to their business, to ensure information security certifications and compliance readiness.</p>
                                <p>ekko specializes in the implementation of Information Security and Compliance Management Systems based on ISO27000 standards within agile and innovative organizations. </p>
                                
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="circle-shape1">
             <img src={shape1} alt="banner" /> 
            </div>

          
            <div className="circle-shape1">
 
            </div> */}
        </section>
    )
}

export default AboutUsContent;